import lunr from "lunr"

class SearchEngine {
  async generateIndex(indexData) {
    this.index = lunr(function () {
      this.ref("id")
      this.field("title", { boost: 10 })
      this.field("url")
      this.field("meta_description")
      this.field("description")
      this.field("category")
      indexData.forEach((item) => {
        this.add(item)
      })
    })

    this.indexData = indexData
  }

  performSearch(query) {
    if (this.index) {
      this.query = query
      const results = this.index.search(this.query)

      if (results.length) {
        return results.map(({ ref }) => this.indexData.find((e) => e.id == ref))
      } else {
        return []
      }
    } else {
      throw new Error(
        "Search index hasn't yet loaded. Run the generateIndex function"
      )
    }
  }
}

export default SearchEngine
