<script>
  import { fly } from 'svelte/transition';
  export let bookmark;

  let host = bookmark.url.match(/^https?:\/\/([^\/:?#]+)(?:[\/:?#]|$)/i)[1]
  let title = bookmark.title ? bookmark.title.split(":")[0] : "?"
  let createdAt =
    new Intl.DateTimeFormat(undefined, {
      day: "numeric",
      month: "long",
      year: "numeric",
    }).format(new Date(bookmark.created_at))

</script>

  <div class="bookmark" transition:fly>
    <div class="bookmark__title">
      {#if bookmark.image_base64 }
        <img src={bookmark.image_base64} alt='Screenshot of Website'>
      {/if}
      <h4>{ bookmark.title }</h4>
    </div>
    <div class="bookmark__right-side">
      {#if bookmark.meta_description }
        <q>{ bookmark.meta_description }</q>
      {/if}
      <div class="bookmark__comment">
        { bookmark.description }
      </div>
    </div>
    <div class="bookmark__left-side">
      <a class="btn btn-default" href={bookmark.url}>
        { title }
        <br />
        <small class="text-muted"> { host }</small>
      </a>
      <div><small>Added: {createdAt }</small></div>
    </div>
  </div>
