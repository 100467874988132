const tally = (arrayOfObjects, fn) => {
  const out = {}
  arrayOfObjects.forEach((element) => {
    const v = fn(element)
    if (out[v] == undefined)
      out[v] = 0
    out[v] += 1
  })
  return Object.keys(out).map((key) => [key, out[key]])
}

export default tally
