<script lang="ts">
  type Chord = {
    name: string;
    patterns: string;
    songs: string[];
  };
  export let chords: Chord[];
  import HarmonyPattern from './HarmonyPattern.svelte';


</script>

<div class="article">
  <div class="bookmark-group">
    {#each chords as chord}
      <HarmonyPattern chord={chord} />
    {/each}
  </div>
</div>
