<script>
  import Bookmark from "./Bookmark.svelte"
  import SearchField from "./SearchField.svelte"
  export let bookmarks = []
  let selectedBookmarks = []

</script>

<div>
  <SearchField bookmarks={bookmarks} bind:value={selectedBookmarks} />
  <div>
    {#if selectedBookmarks.length > 0}
      <article>
        <div class="bookmark-group">
          {#each selectedBookmarks as bookmark (bookmark.url)}
            <Bookmark {bookmark} />
          {/each}
        </div>
      </article>
    {/if}
  </div>
</div>
