<script lang="ts">
  type Chord = {
    name: string;
    patterns: string;
    songs: string[];
  };
  export let chord: Chord;
  let patternLines = [];
  $: patternLines = chord.patterns.split("\n")

  </script>

      <div class="bookmark">
        {chord.name}
        <div class="chord-notes">
          {#each patternLines as line }
            <div class="chord-note">
              {line}
            </div>
          {/each}
        </div>
      </div>

<style>
.chord-notes {
  font-family: serif;
  font-size: 2rem;
}

</style>
