<script>
  import tally from "../utils/tally";

  export let bookmarks = [];
  export let value = [];

  let selected = null;
  let query = "";
  let categories = tally(bookmarks, (b) => b.category);

  import SearchEngine from "../utils/lunr";

  const engine = new SearchEngine();
  let alreadyIndex = false;
  let index = () => {
    if (alreadyIndex) {
      return;
    }
    alreadyIndex = true;
    engine.generateIndex(bookmarks);
  };

  const search = () => {
    selected = null;
    value = engine.performSearch(query);
  };
  const select = (category) => {
    query = "";
    selected = category;
    value = bookmarks.filter((e) => e.category === selected);
  };
</script>

<div class="search">
  <div style="display: flex; gap: 5px; flex-wrap: wrap">
    {#each categories as [category, count]}
      <button on:click={(e) => e.preventDefault() || select(category)}>
        <span class="label" class:active={category == selected}>
          {category} ({count})
        </span>
      </button>
    {/each}
  </div>
</div>
<input
on:keyup={search}
on:click={index}
bind:value={query}
placeholder="search query"
/>

<style>
button {
  background: none;
  border: 0;
  margin: 0;
  padding: 2px;
}
</style>
