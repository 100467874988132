import "index.scss"
import * as Turbo from "@hotwired/turbo"

// Uncomment the line below to add transition animations when Turbo navigates.
// We recommend adding <meta name="turbo-cache-control" content="no-preview" />
// to your HTML head if you turn on transitions. Use data-turbo-transition="false"
// on your <main> element for pages where you don't want any transition animation.
//
// import "./turbo_transitions.js"

// Import all JavaScript & CSS files from src/_components
import components from "bridgetownComponents/**/*.{js,jsx,js.rb,css}"

import AutomountAll from './utils/automount'
import BookmarkApp from './svelte/BookmarkApp.svelte'
import ChordsApp from './svelte/ChordsApp.svelte'

AutomountAll({
  "bookmark-app": {
    component: (node, props) => {
      new BookmarkApp({ target: node, props })
    }
  },
  "chords-app": {
    component: (node, props) => {
      new ChordsApp({ target: node, props })
    }
  }
})

import Comments from "@oom/mastodon-comments/src/comments.js"
import "@oom/mastodon-comments/src/styles.css"

console.log(Comments)
window.customElements.define("oom-comments", Comments);
